import { APITypesV2, PaginatedResponse } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["questionnaire", patientId];
const queryFn =
  (apiClient: APIClient, patientId: string, visitId: string | undefined) =>
  async () => {
    return await apiClient.questionnaire.getQuestionnaireResponses(
      { patientId },
      { visitId }
    ).result;
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

function useQuestionnaireQuery<T = Body>(
  visitId: string | undefined,
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    staleTime: Infinity,
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId, visitId),
  });
}

function getLatestCompleteQuestionnaireResponse(
  data: PaginatedResponse<APITypesV2.QuestionnaireResponse>
) {
  const latestQuestionnaireResponse = data.items.find((response) =>
    // filter out the immediate-risk-assessment responses
    response.questionnaireType.startsWith("onboarding/")
  );

  if (latestQuestionnaireResponse == null) {
    return false;
  }

  return (
    latestQuestionnaireResponse.state ===
    APITypesV2.QuestionnaireResponseState.Complete
  );
}

export function useIsQuestionnaireCompleteForThisVisitQuery(
  visitId: string | undefined,
  options: Omit<QueryOptions<Body, Key, boolean>, "select"> = {}
) {
  return useQuestionnaireQuery(visitId, {
    ...options,
    enabled: Boolean(visitId) && options.enabled,
    select: (data) => getLatestCompleteQuestionnaireResponse(data),
  });
}
