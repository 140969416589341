import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["visits", patientId] as const;

const queryFn = (apiClient: APIClient, patientId: string) =>
  apiClient.visit
    .getPatientVisits(
      { patientId },
      {
        order: APITypesV1.SortOrder.Desc,
        pageSize: 100,
      }
    )
    .result.then((result) => {
      return result.items.map(fromAPI.toVisit);
    });

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useVisitsQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () => queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}
