import type { Benchmark, Measure } from "lib/benchmark";
import { useEffect, useState } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";

export function useBenchmark(measure: Measure, measurement: number) {
  const { data: patient } = usePatientQuery();
  const [benchmark, setBenchmark] = useState<Benchmark>();

  useEffect(() => {
    // dynamically import it because the `benchmarks.ts` file is quite huge,
    // so we want to split it into another chunk when building/bundling.
    // we can replace this when the backend Benchmark API endpoint is introduced
    import("lib/benchmark").then((m) =>
      setBenchmark(m.getBenchmark(patient, measure, measurement))
    );
  }, [patient, measure, measurement]);

  return benchmark;
}
