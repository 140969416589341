import { Typography } from "@cur8/maneki";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { ReactComponent as ChevronIcon } from "assets/icons/16x16/16x16_chevron-right.svg";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import { useConfig } from "render/context/ConfigContext";
import { useLocale } from "render/context/InternationalizationContext";
import { useLatestOptionalConsentsByType } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { ConsentCard } from "render/views/ProfileView/components/DataAndPrivacySection/components/ConsentCard";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function DataAndPrivacySection() {
  const patientQuery = usePatientQuery();
  const nav = {
    consents: useNav(paths.dataAndPrivacyConsents),
    exportData: useNav(paths.dataAndPrivacyExportData),
  };

  const { appConfig } = useConfig();
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);
  const privacyPolicyURL =
    patientQuery.data?.preferredCountry === "GB"
      ? `${appConfig.legalApiUrl}/pdfs/privacy-policy/2024-05-15-UK-Neko-Clinic-Privacy-Notice.${languageCode}.pdf`
      : `${appConfig.legalApiUrl}/documents/privacy-policy/se/2025-01-privacy-policy.${languageCode}.pdf`;
  const { data: latestConsents } = useLatestOptionalConsentsByType();
  const hasGDPRConsents =
    latestConsents?.gdpr && latestConsents.gdpr.length > 0;
  const hasOtherConsents =
    latestConsents?.other && latestConsents.other.length > 0;
  const hasStudyConsents =
    latestConsents?.studies && latestConsents.studies.length > 0;
  const sortedProductAndServicesConsents = [
    ...(latestConsents?.gdpr ?? []),
    ...(latestConsents?.other ?? []),
  ].sort((a, b) =>
    a.consentMetadata.consentId.localeCompare(b.consentMetadata.consentId)
  );
  const sortedStudyConsents = latestConsents?.studies.sort((a, b) =>
    a.consentMetadata.consentId.localeCompare(b.consentMetadata.consentId)
  );

  if (latestConsents == null) {
    return undefined;
  }

  const navToConsent = (consentId: string) => nav.consents.go({ consentId });

  return (
    <section data-hj-suppress className={styles.studiesSection}>
      <div className={styles.title}>
        <Typography variant="display-s">
          <Trans.Title />
        </Typography>
        <Typography variant="body-m" color="subtle">
          <Trans.Paragraph link={privacyPolicyURL} />
        </Typography>
      </div>

      {hasOtherConsents || hasGDPRConsents ? (
        <section className={styles.section}>
          <Typography variant="title-m">
            <Trans.Products />
          </Typography>
          <div className={styles.cardsWrapper}>
            {sortedProductAndServicesConsents.map((consent) => (
              <ConsentCard
                key={consent.relevantSignature?.consentId}
                consent={consent}
                onOpenConsentClick={navToConsent}
              />
            ))}
          </div>
        </section>
      ) : undefined}

      {hasStudyConsents ? (
        <section className={styles.section}>
          <Typography variant="title-m">
            <Trans.ResearchStudies />
          </Typography>
          <div className={styles.cardsWrapper}>
            {sortedStudyConsents?.map((consent) => (
              <ConsentCard
                key={consent.relevantSignature?.consentId}
                consent={consent}
                onOpenConsentClick={navToConsent}
              />
            ))}
          </div>
          <Typography variant="body-s" color="subtle">
            <Trans.ResearchStudiesDescription />
          </Typography>
        </section>
      ) : undefined}

      {patientQuery.data?.hasVerifiedIdentity && (
        <section className={styles.section}>
          <Typography variant="title-m">
            <Trans.GdprExport />
          </Typography>
          <PathLink className={styles.gdpr} to={nav.exportData.to({})}>
            <div className={styles.text}>
              <Typography variant="body-m" color="default">
                <Trans.RequestPersonalData />
              </Typography>
              <Typography variant="body-xs" color="subtle">
                <Trans.CreateFiles />
              </Typography>
            </div>
            <ChevronIcon />
          </PathLink>
        </section>
      )}
    </section>
  );
}
