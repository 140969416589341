import { Typography } from "@cur8/maneki";
import type { Benchmark, Measure } from "lib/benchmark";
import { PatientGroup } from "./PatientGroup";
import * as Trans from "./trans";

type ComparisonProps = {
  benchmark: Benchmark;
};

export function Comparison({ benchmark }: ComparisonProps) {
  const Measurement = measures[benchmark.measure];
  const Relation = getRelation(benchmark.quotient, benchmark.isBetter);

  return (
    <Typography variant="body-m" color="subtle">
      <Trans.Benchmark
        group={<PatientGroup benchmark={benchmark} />}
        measurement={<Measurement />}
        relation={<Relation percentage={benchmark.percentage} />}
      />
    </Typography>
  );
}

const measures: Record<Measure, () => string> = {
  diastolicBloodPressure: Trans.BloodPressure,
  gripStrengthLeft: Trans.GripStrength,
  gripStrengthRight: Trans.GripStrength,
  hba1c: Trans.BloodSugar,
  ldl: Trans.Ldl,
  nonHdl: Trans.NonHdl,
  systolicBloodPressure: Trans.BloodPressure,
};

function getRelation(quotient: number, isBetter: boolean) {
  if (quotient === 1) {
    return Trans.Matches;
  }
  if (isBetter) {
    return Trans.BetterThan;
  }
  if (quotient < 1) {
    return Trans.LowerThan;
  }
  return Trans.HigherThan;
}
