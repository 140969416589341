import { Typography } from "@cur8/maneki";
import { useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as ChangeIcon } from "assets/icons/16x16/16x16_arrow-up.svg";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/WeightDetails";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useWeightMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { changeLabel, weightValue } from "./lib";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function MetricWeightDetails() {
  const patientQuery = usePatientQuery();
  const { data } = useWeightMetric();

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });

  if (!patientQuery.data) {
    return null;
  }

  if (!data) {
    return null;
  }

  const metricHistory = getMetricHistory(data, scanNum);
  const { cur, prev } = metricHistory;
  const change =
    cur && prev ? cur.unit.kilograms - prev.unit.kilograms : undefined;
  const changeDirection =
    change != null ? calculateDirection(change) : undefined;

  return (
    <MetricDetails
      scanDate={cur?.measurement.timestampStart}
      displayValue={weightValue({
        patient: patientQuery.data,
        value: cur?.unit,
      })}
      previousDisplayValue={weightValue({
        patient: patientQuery.data,
        value: prev?.unit,
      })}
      changeDisplayValue={
        change != null && (
          <div className={styles.row}>
            <Typography variant="body-s">
              {changeLabel({ diff: { kilograms: change } })}
            </Typography>

            {weightValue({
              patient: patientQuery.data,
              value: { kilograms: change },
            }).flatMap((value) => {
              return <Typography variant="body-s">{value}</Typography>;
            })}
          </div>
        )
      }
      changeIcon={
        change != null && (
          <ChangeIcon className={styles.icon} data-change={changeDirection} />
        )
      }
      label={<Trans.MetricName />}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}

function calculateDirection(change: number) {
  if (change < 0) {
    return "decrease";
  }
  if (change > 0) {
    return "increase";
  }
  return "noChange";
}
