import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useConfig } from "../ConfigContext";
import { AuthenticationMethod, Session } from "./types";
export { useMsalAuthentication } from "@azure/msal-react";

type Props = {
  children: ReactNode;
  msal: IPublicClientApplication;
};

export function MSALContext({ children, msal }: Props) {
  const appInsights = useAppInsights();

  const [callbackId] = useState(() => {
    function setAuthenticatedUserContext(account: AccountInfo | null) {
      if (account) {
        const session = createSession(account);
        appInsights.setAuthenticatedUserContext(session.patientId);
      } else {
        appInsights.clearAuthenticatedUserContext();
      }
    }

    /**
     * Event list
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
     */
    return msal.addEventCallback(
      ({ error, eventType, payload }) => {
        switch (eventType) {
          case EventType.LOGIN_FAILURE:
            return appInsights.trackException({
              error: error ?? undefined,
              exception: error ?? undefined,
              severityLevel: SeverityLevel.Error,
            });
          case EventType.LOGIN_SUCCESS: {
            const authResult = payload as AuthenticationResult;
            msal.setActiveAccount(authResult.account);
            return setAuthenticatedUserContext(authResult.account);
          }
          case EventType.INITIALIZE_START:
            return setAuthenticatedUserContext(msal.getActiveAccount());
        }
      },
      [
        EventType.INITIALIZE_START,
        EventType.LOGIN_FAILURE,
        EventType.LOGIN_SUCCESS,
      ]
    );
  });

  useEffect(() => {
    if (callbackId) {
      return () => msal.removeEventCallback(callbackId);
    }
  }, [callbackId, msal]);

  return <MsalProvider instance={msal}>{children}</MsalProvider>;
}

export function useMSAL() {
  const { instance } = useMsal();
  const config = useConfig();

  const { account, auth, session } = stateOf(instance);

  const stepUpAccess = useCallback(
    async (method: "psk" | "idv", redirectStartPage?: string) => {
      return instance.acquireTokenRedirect({
        scopes: config.appConfig.msal.scopes.token,
        redirectUri: window.location.origin,
        extraQueryParameters: {
          acr_values: method,
        },
        redirectStartPage: redirectStartPage,
      });
    },
    [instance, config.appConfig.msal.scopes.token]
  );

  const logout = useCallback(
    async (props: Partial<{ postLogoutRedirectUrl: string }> = {}) => {
      instance.logoutRedirect({
        postLogoutRedirectUri: props.postLogoutRedirectUrl,
        idTokenHint: account?.idToken,
      });
    },
    [account, instance]
  );

  return {
    logout,
    stepUpAccess,
    instance,
    auth,
    session,
  };
}

export function useSession() {
  const { session } = useMSAL();
  if (!session) {
    throw new Error("No Session account");
  }
  return session;
}

function stateOf(instance: IPublicClientApplication) {
  const account = instance.getActiveAccount();
  return {
    account,
    auth: account && { account },
    session: account && createSession(account),
  };
}

type Claims = {
  extension_PatientId?: string;
  newuser?: string;
  amr?: AuthenticationMethod[];
  step_up_unmet?: string;
};

function createSession(account: AccountInfo): Session {
  const claims = account.idTokenClaims as Claims;
  const patientId = claims.extension_PatientId;

  // HACK: Majority of the code base assumes patientId is always present,
  //       so let's throw an error so we have explicit types.
  if (!patientId) {
    throw new Error("Patient ID not found in claims");
  }

  return {
    patientId: patientId,
    isNewUser: claims.newuser === "True",
    authenticationMethods: claims.amr ?? [],
    stepUpUnmet: claims.step_up_unmet === "True",
    mfa: claims.amr?.includes("mfa") ?? false,
  };
}
