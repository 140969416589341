import { localize } from "render/context/InternationalizationContext";

export const BenchmarkTitle = localize({
  en_GB: "Benchmark",
  sv_SE: "Benchmark",
});

type BenchmarkProps = {
  group: JSX.Element;
  measurement: JSX.Element;
  relation: JSX.Element;
};

export const Benchmark = localize<BenchmarkProps>({
  en_GB: ({ group, measurement, relation }) => (
    <>
      Your {measurement} {relation} Neko members like you [{group}].
    </>
  ),
  sv_SE: ({ group, measurement, relation }) => (
    <>
      Ditt {measurement} {relation} Neko-medlemmarna som du [{group}].
    </>
  ),
});

export const TypicalMember = localize({
  en_GB: "Typical Neko member",
  sv_SE: "Typisk Neko-medlem",
});

export const YourResult = localize({
  en_GB: "Your result",
  sv_SE: "Ditt resultat",
});

export const BloodPressure = localize({
  en_GB: "blood pressure",
  sv_SE: "blodtryck",
});

export const BloodSugar = localize({
  en_GB: "long term blood sugar",
  sv_SE: "långtidsblodsocker",
});

export const GripStrength = localize({
  en_GB: "grip strength",
  sv_SE: "greppstyrka",
});

export const Ldl = localize({
  en_GB: "LDL blood fat levels",
  sv_SE: "LDL-nivåer",
});

export const NonHdl = localize({
  en_GB: "non-HDL blood fat levels",
  sv_SE: "non-HDL-nivåer",
});

export const BetterThan = localize<{ percentage: number }>({
  en_GB: ({ percentage }) => `is better than ${percentage}% of`,
  sv_SE: ({ percentage }) => `är bättre än hos ${percentage}% av`,
});

export const HigherThan = localize<{ percentage: number }>({
  en_GB: ({ percentage }) => `is higher than ${percentage}% of`,
  sv_SE: ({ percentage }) => `är högre än hos ${percentage}% av`,
});

export const LowerThan = localize<{ percentage: number }>({
  en_GB: ({ percentage }) => `is lower than ${percentage}% of`,
  sv_SE: ({ percentage }) => `är lägre än hos ${percentage}% av`,
});

export const Matches = localize({
  en_GB: "matches other",
  sv_SE: "matchar andra",
});

export const Males = localize({
  en_GB: "Males",
  sv_SE: "Män",
});

export const Females = localize({
  en_GB: "Females",
  sv_SE: "Kvinnor",
});

export const YearsOld = localize<{ age: number | string }>({
  en_GB: ({ age }) => `${age} years old`,
  sv_SE: ({ age }) => `${age} år`,
});
