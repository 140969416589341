import { LDL } from "@cur8/health-risks-calc";
import { useQueryParams } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { calcScore2 } from "lib/scores/score2/calcScore2";
import { isScore2HighRisk } from "lib/scores/score2/score2";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { Benchmark } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Benchmark/Benchmark";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import {
  useBrachialPressureMetric,
  useLdlMetric,
  useNonHdlMetric,
  useSmokingMetric,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricLDLDetails() {
  const { data: ldl } = useLdlMetric();
  const { data: nonHDL } = useNonHdlMetric();
  const { data: brachialPressure } = useBrachialPressureMetric();
  const { data: smoking } = useSmokingMetric();
  const { data: patient } = usePatientQuery();
  const age = patient?.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = ldl?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });

  if (!ldl || !nonHDL || !brachialPressure || !smoking || !patient) {
    return null;
  }

  const { cur: current, prev: previous } = getMetricHistory(ldl, scanNum);

  const score2 = calcScore2({
    patient,
    date: DateTime.now(),
    nonHDL: getMetricHistory(nonHDL, scanNum).cur,
    brachial: {
      left: getMetricHistory(brachialPressure.left, scanNum).cur,
      right: getMetricHistory(brachialPressure.right, scanNum).cur,
    },
    smoking: getMetricHistory(smoking, scanNum).cur,
  });

  const score2HighRisk = isScore2HighRisk(age, score2?.score);
  const ranges = LDL.rangesFor({
    age,
    isScore2HighRisk: score2HighRisk ?? false,
  });

  const values = {
    subLabel: "mmol/L",
    value: current?.unit["mmol/L"],
    previousValue: previous?.unit["mmol/L"],
    metricRating: current && ranges.findRisk(current.unit),
    scanDate: current?.measurement.timestampStart,
    ranges: toChartRanges(ranges.entries),
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      benchmark={
        values.value != null && (
          <Benchmark
            measure="ldl"
            measurement={values.value}
            unit={values.subLabel}
          />
        )
      }
    />
  );
}
