import { Typography } from "@cur8/maneki";
import { clamp } from "lib/math";
import { ReactNode } from "react";
import styles from "./styles.module.sass";

type MeasurementProps = {
  /** @default 'neutral' */
  kind?: "neutral" | "negative" | "positive";
  label: JSX.Element;
  quotient: number;
  unit: ReactNode;
  value: number | string;
};

export function Measurement({
  kind,
  label,
  quotient,
  unit,
  value,
}: MeasurementProps) {
  return (
    <div className={styles.measurement}>
      <div className={styles.value}>
        <Typography variant="numeral-s">{value}</Typography>
        <Typography variant="body-s">{unit}</Typography>
      </div>
      <div
        className={styles.bar}
        data-kind={kind}
        style={{
          maxWidth: quotient < 1 ? "calc(100% - 10px)" : undefined,
          width: `${clamp(quotient, 0, 1) * 100}%`,
        }}
      >
        <Typography variant="label-s">{label}</Typography>
      </div>
    </div>
  );
}
