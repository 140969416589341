import { Sex } from "@cur8/rich-entity";
import type { Benchmark } from "lib/benchmark";
import * as Trans from "./trans";

type PatientGroupProps = {
  benchmark: Benchmark;
};

export function PatientGroup({ benchmark }: PatientGroupProps) {
  const SexLabel = sexLabels[benchmark.sex];

  return (
    <>
      <SexLabel /> <Trans.YearsOld age={benchmark.ageGroup} />
    </>
  );
}

// TODO: should ideally be the same source as DocUI
// https://dev.azure.com/cur8/TheRigg/_git/DoctorUI?path=/src/render/pages/DashboardPage/components/EntryPoint/layouts/ArterialHealthLayout/components/BenchmarkResultDescription/BenchmarkResultDescription.tsx&version=GBmain&line=13&lineEnd=17&lineStartColumn=1&lineEndColumn=3&lineStyle=plain&_a=contents
const sexLabels: Record<Sex, () => string> = {
  [Sex.Male]: Trans.Males,
  [Sex.Female]: Trans.Females,
  [Sex.Unknown]: () => "",
};
