import { Typography } from "@cur8/maneki";
import type { Measure } from "lib/benchmark";
import { type ReactNode } from "react";
import { Comparison } from "./Comparison";
import { Measurement } from "./Measurement";
import styles from "./styles.module.sass";
import * as Trans from "./trans";
import { useBenchmark } from "./useBenchmark";

type BenchmarkProps = {
  measure: Measure;
  measurement: number;
  unit: ReactNode;
};

export function Benchmark({ measure, measurement, unit }: BenchmarkProps) {
  const benchmark = useBenchmark(measure, measurement);

  if (!benchmark) {
    return;
  }

  return (
    <>
      <hr className={styles.keyline} />
      <div className={styles.benchmark}>
        <div className={styles.text}>
          <Typography variant="title-m">
            <Trans.BenchmarkTitle />
          </Typography>
          <Typography variant="body-m" color="subtle">
            <Comparison benchmark={benchmark} />
          </Typography>
        </div>
        <Measurement
          label={<Trans.TypicalMember />}
          quotient={benchmark.quotient > 1 ? 1 / benchmark.quotient : 1}
          unit={unit}
          value={Math.round(benchmark.average)}
        />
        <Measurement
          kind={benchmark.isBetter ? "positive" : "negative"}
          label={<Trans.YourResult />}
          quotient={benchmark.quotient < 1 ? benchmark.quotient : 1}
          unit={unit}
          value={measurement}
        />
      </div>
    </>
  );
}
