import { NonHDL } from "@cur8/health-risks-calc";
import { useQueryParams } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { Benchmark } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Benchmark/Benchmark";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { useIsScore2HighRisk } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/hooks/useIsScore2HighRisk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useNonHdlMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricNonHDLDetails() {
  const { data } = useNonHdlMetric();
  const { data: patient } = usePatientQuery();
  const isScore2HighRisk = useIsScore2HighRisk();
  const age = patient?.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });

  if (!data || !patient) {
    return undefined;
  }

  const { cur: current, prev: previous } = getMetricHistory(data, scanNum);

  const riskRanges = NonHDL.rangesFor({
    age,
    sex: patient.sex,
    isScore2HighRisk: isScore2HighRisk || false,
  });

  const values = {
    subLabel: "mmol/L",
    value: current?.unit["mmol/L"],
    previousValue: previous?.unit["mmol/L"],
    metricRating: current && riskRanges.findRisk(current.unit),
    scanDate: current?.measurement.timestampStart,
    ranges: toChartRanges(riskRanges.entries),
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      benchmark={
        values.value != null && (
          <Benchmark
            measure="nonHdl"
            measurement={values.value}
            unit={values.subLabel}
          />
        )
      }
    />
  );
}
