import { Typography } from "@cur8/maneki";
import { DateTime } from "luxon";
import { Separator } from "render/ui/presentation/Separator/Separator";
import { PostScanShareViewEntrypoint } from "render/views/PostScanShareView/components/PostScanShareViewEntrypoint";
import { MetricsSection } from "render/views/Report/AppointmentDataView/components/MetricsSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ScanDataTabProps {
  latestScanDate: DateTime;
  headerHeight: number;
}

export function ScanDataTab({
  latestScanDate,
  headerHeight,
}: ScanDataTabProps) {
  return (
    <div className={styles.container} data-testid="scan-data-tab">
      <div className={styles.header}>
        <div className={styles.titles}>
          <Typography variant="display-s">
            <Trans.Title />
          </Typography>
          <div className={styles.updated}>
            <Typography variant="body-s" color="subtle">
              <Trans.LastUpdated />
            </Typography>
            <Typography variant="body-s">
              &nbsp;
              {latestScanDate.toFormat("dd MMMM yyyy")}
            </Typography>
          </div>
          <Separator margin="24px 0 24px" width="24px" />
        </div>
        <div className={styles.actions}>
          <PostScanShareViewEntrypoint />
        </div>
      </div>
      <MetricsSection headerHeight={headerHeight} />
      <div className={styles.studies}>
        <Typography variant="body-s">
          <Trans.Disclaimer />
        </Typography>
      </div>
    </div>
  );
}
